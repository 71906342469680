import React, {useState, useEffect} from "react"
import {navigate} from "gatsby"
import qs from "qs"
import _ from "lodash"
import {AppSync} from "../../libraries/appsync";
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import NewsDetail from "../../components/NewsDetail"
import PageNavigation from "../../components/PageNavigation"
import Button from "../../components/Button"
import {Preview, cryptString} from "../../libraries/preview";

export default ({location}) => {
  const [loading, setLoading] = useState(false)
  const [canRead, setCanRead] = useState(false)
  const [news, setNews] = useState([])
  const newsId = parseInt(_.get(qs.parse(location.search, {ignoreQueryPrefix: true}), "id"))

  useEffect(() => {
    setLoading(true)

    Preview(cryptString(location)).then(customDateTime => {
      const newsQuery = `(news_id:${newsId})`

      AppSync.post("/", {
        query: `
          query getNews {
            getNews${newsQuery} {
              site_c
              srvc_tc
              news_id
              title
              article
              stt_ts
              end_ts
              tags
              thumbnail_url
              dsptgt_tc
              tgtmember_tc
            }
          }
        `
      }).then(res => {
        const memberType = _.get(JSON.parse(window.localStorage.getItem("personal")), "member_type")
        const news = _.get(res.data, "data.getNews", null)
        if (news !== null && news.tgtmember_tc !== undefined && news.tgtmember_tc !== null) {
          const allowedMemberTypes = news.tgtmember_tc.split(',')
          if (allowedMemberTypes.includes('0')) {
            setCanRead(true)
          } else if (memberType == null) {
            navigate(`/login/?continue=${encodeURIComponent(location.href)}`)
          } else if (allowedMemberTypes.includes(memberType.toString())) {
            setCanRead(true)
          }
          console.log(memberType, allowedMemberTypes)
        }
        setNews(news)
        setLoading(false)
      })
    })
  }, [newsId])

  return (
    <Layout>
      <SEO title={_.get(news, "title", "")} description={_.get(news, "body", "")}/>
      <section className="inner">
        <NewsDetail loading={loading} item={news} canRead={canRead}/>
        <PageNavigation>
          <Button type={"button"} attr={{
            onClick: () => navigate("/news/")
          }}>一覧へ戻る</Button>
        </PageNavigation>
      </section>
    </Layout>
  )
}