import {AppSync} from "./appsync"
import _ from "lodash"
import moment from "moment-timezone"
import qs from "qs"

export const Preview = cryptString => new Promise((resolve, reject) => {
  if (cryptString) {
    AppSync.post("/", {
      query: `
        query getCmsPreview {
          getCmsPreview(mode:d, string:"${cryptString}") {
            customDateTime
            startDateTime
            endDateTime
          }
        }
      `
    }).then(res => {
      const preview = _.get(res, "data.data.getCmsPreview", null)
      if (preview && preview.customDateTime) {
        console.info(`%cPage Preview [${moment.tz(preview.customDateTime, "Asia/Tokyo").format("YYYY/MM/DD HH:mm:ss")}] expire [${moment.tz(preview.endDateTime, "Asia/Tokyo").format("MM/DD HH:mm:ss")}]%c`, "color:blue", "")
        resolve(preview.customDateTime)
      } else {
        resolve(null)
      }
    }).catch(err => {
      resolve(null)
    })
  } else {
    resolve(null)
  }
})

export const cryptString = (location) => _.get(qs.parse(location.search, {ignoreQueryPrefix: true}), "p")