import React from "react"

export default ({dsptgt_tc}) => {
  // カテゴリラベル
  const categoryLabelMaster = {
    0: '全公開',
    1: '要ログイン',
    2: '有料会員',
    3: '月額会員のみ',
    4: '年額会員のみ'
  }
  let categoryLabel = false
  if (dsptgt_tc in categoryLabelMaster) {
    categoryLabel = categoryLabelMaster[dsptgt_tc]
  }
  if (categoryLabel && dsptgt_tc !== '0') {
    return (<span className="category">{categoryLabel}</span>)
  } else {
    return null
  }
}